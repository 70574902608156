import { EstimatorService } from "@rsfApp/app/src/Estimator/EstimatorService";
import { GafEstimateEditor } from "app/src/Estimator/GafEstimateEditorComponent";
import { ProductSelectorCtrl } from "@rsfApp/app/src/Estimator/ProductSelectorCtrl";
import { LoanCalc } from "@rsfApp/app/src/Estimator/LoanCalculator";
import { DiscountEditor } from "@rsfApp/app/src/Estimator/Discounts/DiscountEditor";
import { TemplateEditorCtrl } from "@rsfApp/app/src/Estimator/TemplateEditorCtrl";
import { GafEstimateLineItemComponent } from "app/src/Estimator/GafEstimateLineItemComponent";
import { GafEstimateGroupComponent } from "app/src/Estimator/GafEstimateGroupComponent";
import { OpeningsWizard } from "@rsfApp/app/src/Estimator/OpeningsWizard";
import { SalesTaxEditor } from "@rsfApp/app/src/Estimator/SalesTaxEditor";
import { WindowAnnotatingComponent } from "@rsfApp/app/src/Estimator/WindowAnnotatingComponent";
import { react2angular } from "react2angular";
import LineItemEditor from "@rsfApp/app2/src/components/LineItemEditor/LineItemEditor";
import DiscountChooser from "@rsfApp/app2/src/components/Discount/Chooser";
import PaymentTermChooser from "@rsfApp/app2/src/components/PaymentTerm/Chooser";
import PriceDrawer from "@rsfApp/app2/src/components/PriceDrawer";
import MarkupEditor from "@rsfApp/app2/src/components/Estimate/MarkupEditor";
import EstimateTemplateModal from "@rsfApp/app2/src/components/JobTabs/EstimateTab/EstimateTemplateModal";
import FinanceOptionsButton from "@rsfApp/app2/src/components/Homevest/FinanceOptionsButton";
import CommissionActions from "@rsfApp/app2/src/components/JobTabs/EstimateTab/CommissionActions";
import RoomSummary from "@rsfApp/app2/src/components/JobTabs/EstimateTab/RoomSummary";

export class GafEstimatorRoutes {
  public static load(app: ng.IModule) {
    app.controller("ProductSelectorCtrl", ProductSelectorCtrl);
    app.controller("TemplateEditorCtrl", TemplateEditorCtrl);
    app.controller("OpeningsWizard", OpeningsWizard);
    app.component("estimateEditor", new GafEstimateEditor());
    app.component("estimateLineItem", new GafEstimateLineItemComponent());
    app.component("estimateGroup", new GafEstimateGroupComponent());
    app.component("loanCalc", new LoanCalc());
    app.component("discountEditor", new DiscountEditor());
    app.component("salesTaxEditor", new SalesTaxEditor());
    app.component("windowAnnotator", new WindowAnnotatingComponent());
    app.component(
      "priceDrawerWrapper",
      react2angular(
        PriceDrawer,
        ["orgId", "jobId", "currentEstimate", "openDrawer", "saveEditorEstimate"],
        ["$state", "EstimatorService", "ConfirmDialog"]
      )
    );
    app.service("EstimatorService", EstimatorService);

    app.component(
      "lineItemEditor",
      react2angular(
        LineItemEditor,
        ["lineItem", "estimate", "measurementId", "controller", "estimateGroup"],
        ["$analytics"]
      )
    );

    app.component(
      "discountChooser",
      react2angular(DiscountChooser, ["onSelect", "existing", "showHeaderText", "total", "update", "destroy", "params"])
    );
    app.component("financeOptionsButton", react2angular(FinanceOptionsButton, ["estimateId"]));
    app.component(
      "paymentTermChooser",
      react2angular(PaymentTermChooser, [
        "orgId",
        "paymentTerm",
        "showHeaderText",
        "addItem",
        "addTemplate",
        "removeItem",
        "updateItem",
        "doneButton",
        "view",
      ])
    );

    app.component(
      "markupEditor",
      react2angular(MarkupEditor, ["orgId", "estimateOrGroup", "lineItemEditorOpen", "updated"])
    );
    app.component(
      "estimateTemplateModal",
      react2angular(EstimateTemplateModal, ["show", "readOnly", "saveAs", "select"])
    );
    app.component("commissionActions", react2angular(CommissionActions, ["estimateId"]));
    app.component("roomSummary", react2angular(RoomSummary, ["estimateGroup"]));
  }

  public static configure($stateProvider: ng.ui.IStateProvider): void {
    $stateProvider.state("job_header.job_show.estimate.homevest", <ng.ui.IState>{
      url: "/homevest",
      views: {},
    });
    $stateProvider.state("job_header.job_show.estimate.homevest_route", <ng.ui.IState>{
      url: "/homevest/:route",
      views: {},
    });
  }
}
